function getToken() {
    let arr = document.getElementsByName('csrf-token');
    if (arr.length == 0) return '';
    return arr[0].getAttribute('content');
}
/**
 * @param {string} url
 * @param {Object} data
 */
async function sendData(url, data, method, typeData) {
    let json,
        headers = {
            'Accept': 'application/json',
            'X-CSRF-Token': getToken()
        };

    if (typeData != 'form') {
        data = JSON.stringify(data);
        headers['Content-Type'] = 'application/json'
    }

    const response = await fetch(import.meta.env.VITE_REMOTE_API_SERVER + url, {
        method: method || 'POST',
        headers: headers,
        body: data
    })
    const contentType = response.headers.get('content-type');

    if (contentType == 'application/json') {
        json = await response.json();
        // if (json.message) {
        //     throw new Error(json.message);
        // }
    } else {
        throw responseError(response, url, contentType);
    }
    return (json || {});
}

/**
 * @param {string} url
 */
async function get(url, data) {
    let json;
    if (data) url += new URLSearchParams(data);
    const response = await fetch(import.meta.env.VITE_REMOTE_API_SERVER + url);
    const contentType = response.headers.get('content-type');
    if (contentType == 'application/json' && response.ok) {
        json = await response.json();
    } else {
        throw responseError(response, url, contentType);
    }
    return (json || {});
}

/**
 * @param {Response} data
 * @param {string} url
 */
function responseError(data, url, contentType) {
    const message =
        'Невозможно отправить/принять данные: нет ответа или неверный тип ответа \n' +
        'Код ответа: ' + data.status + '\n' +
        'URL запроса: ' + url + '\n' +
        'Тип ответа: ' + contentType;
    return message;
}

const getUrl = (function () {
    let path = window.location.pathname;
    if (path[path.length - 1] == '/') {
        path = path.slice(0, -1);
    }

    const prev = path.slice(0, path.lastIndexOf('/'));
    return {
        "path": path,
        "previous": prev,
        "previousName": prev.slice(prev.lastIndexOf('/') + 1),
        "slug": path.slice(path.lastIndexOf('/') + 1),
        "hash": window.location.hash
    }
})()

const getCookie = (function () {
    let cookie = {};
    const rows = document.cookie.split('; ');
    rows.forEach(row => {
        const cookieRow = row.split('=');
        if (cookieRow[1]) {
            cookie[cookieRow[0]] = cookieRow[1];
        } else {
            cookie[cookieRow[0]] = cookieRow[0];
        }
    })
    return cookie;
})();

function setCookie(name, value) {
    document.cookie = encodeURIComponent(name) + '=' + encodeURIComponent(value) + '; path=/';
}
function removeCookie(name) {
    document.cookie = encodeURIComponent(name) + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
}


const searchParams = new URLSearchParams(document.location.search);
function changeSearchParams(param, value = undefined) {
    if (value != "" && value) {
        searchParams.set(param, value);
    } else {
        searchParams.delete(param);
    }
    const stringParams = searchParams.toString();
    if (stringParams == '') {
        if (getUrl.slug.indexOf('?') > -1) {
            history.pushState('', '', getUrl.path);
        }
    } else {
        history.pushState('', '', '?' + stringParams);
    }
}

const checkMobile = (() => {
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent) ||
        window.innerWidth < 992
    ) {
        return true;
    }
    return false
})();

export {
    searchParams,
    checkMobile,
    getUrl,
    getCookie,
    sendData,
    get,
    setCookie,
    removeCookie,
    changeSearchParams,
};